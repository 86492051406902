import React from 'react';
import { Box, Button, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FmdGoodTwoToneIcon from '@mui/icons-material/FmdGoodTwoTone';
import { suboriginalKey } from './constNewsLetter';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ButtonGroup from '@mui/material/ButtonGroup';
const NewsLetter = ({homeDate,alldata,themeData, substoredEncryptedKey, webLocationLength,mainTheme})=> {
    const [openOrder, setOpenOrder] = React.useState(false);
    const handleClickOpenOrder = () => {
      setOpenOrder(true);
    };
    const handleCloseOrder = () => {
      setOpenOrder(false);
    };
    const backgroundStyle = homeDate.data.is_page_bg === 1
      ? { backgroundColor: homeDate.data.page_bg_color }
      : {
          backgroundImage: {
              xs: `url(${homeDate.data.image_medium})`,
              sm: `url(${homeDate.data.image_medium})`,
              md: `url(${homeDate.data.image})`,
              lg: `url(${homeDate.data.image})`
          }
    };
    let btn_order_now_urls;
    alldata?.order_now_urls?.map((urls)=>{
      btn_order_now_urls = urls?.url;
    })
    if (suboriginalKey == substoredEncryptedKey) {
      return (
        <React.Fragment>
          {substoredEncryptedKey == suboriginalKey && (
            <React.Fragment>
              <Box sx={{position:'relative'}}>
                <Box id='News_letter' sx={{position:'absolute',top:'-180px',height:'100%',zIndex:'-1',width:'80%'}}></Box>
                <Box
                    sx={{
                        height:'480px',
                        ...backgroundStyle,
                        // boxShadow:'inset 0 0 0 2000px rgb(0 0 0 / 40%)',
                        display:'flex',
                        flexDirection:'column',
                        justifyContent:'center',
                        alignItems:'center',
                        backgroundPosition:'center',
                        backgroundSize:'cover',
                        backgroundRepeat:'no-repeat',
                    }}
                >
                  <div class="div_css">
                  { homeDate.action == 'show' && (
                    <Box sx={{my:1,}}>
                        <Typography className='title_textlimit'
                            sx={{textAlign:'center'}}
                            variant="h4" 
                            dangerouslySetInnerHTML={{ __html: homeDate?.title }} />
                    </Box>
                  )}
                    <Box sx={{my:1}}>
                        <Typography className='des_textlimit'
                            sx={{textAlign:'center'}}
                            variant="h4" 
                            dangerouslySetInnerHTML={{ __html: homeDate?.data?.description}} />
                    </Box>
                    </div>
                    <Box sx={{my:1}}>
                      {mainTheme?.layout == 5 && (
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled button group"
                        >
                            <Button
                            sx={{
                                my:1.5,
                                backgroundColor:`${themeData?.button_color}`,
                                color:`${themeData?.button_font_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                fontWeight:600,
                                '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                }
                            }}
                            href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                            onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}
                            >
                              Order Now
                            </Button>
                            <Button onClick={webLocationLength > 1 ? handleClickOpenOrder : ''} href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                        </ButtonGroup>
                      )}
                      {mainTheme.layout == 1 ? (
                        <Button 
                          sx={{
                          backgroundColor:`${themeData?.button_color}`,
                          color:`${themeData?.button_font_color}`,
                          borderRadius:'50px',
                          fontWeight:600,
                          '&:hover': {
                              backgroundColor:`${themeData?.button_font_color}`,
                              color:`${themeData?.button_color}`,
                              border:`1px solid ${themeData?.button_color}`,
                              borderRadius:'50px',
                          }
                          }} 
                          href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                          onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                            Order Now
                        </Button>
                      ) : null }
                      {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                        <Button 
                            sx={{
                            backgroundColor:`${themeData?.button_color}`,
                            color:`${themeData?.button_font_color}`,
                            borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                            fontWeight:600,
                            '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                '& .ads-click-icon': {
                                  color: `${themeData?.button_color}`,
                                }
                            }
                            }} 
                            href={webLocationLength == 1 ? `${btn_order_now_urls}` : ''}
                            onClick={webLocationLength > 1 ? handleClickOpenOrder : ''}>
                              {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                              Order Now
                        </Button>
                      ) : null}
                  </Box>
                </Box>
              </Box>
              <Dialog
                  open={openOrder}
                  onClose={handleCloseOrder}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
              >
                {mainTheme.layout == 1 ? (
                    <Button sx={{
                        position:'absolute',
                        zIndex:10,
                        right:'5px',
                        top:'5px',
                        borderRadius:'5px',
                        padding:0,
                        margin:0,
                        maxWidth:'30px',
                        minWidth:'30px',
                        width:'30px',
                        height:'30px',
                        boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleCloseOrder}
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                ) : null}
                {mainTheme.layout == 2 ? (
                  <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'50%',
                      maxWidth:'30px',
                      minWidth:'30px',
                      padding:0,
                      margin:0,
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                  }} 
                  onClick={handleCloseOrder}
                  color='error' 
                  variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                ) : null}
                  {mainTheme.layout == 3 ? (
                    <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'5px',
                      padding:0,
                      margin:0,
                      maxWidth:'30px',
                      minWidth:'30px',
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                  }} 
                  onClick={handleCloseOrder}
                  color='error' 
                  variant='contained'>
                      <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                  </Button>
                  ) : null}
                  {mainTheme.layout == 4 ? (
                    <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'50%',
                      maxWidth:'30px',
                      minWidth:'30px',
                      padding:0,
                      margin:0,
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleCloseOrder}
                    color='error' 
                    variant='contained'><CloseIcon sx={{color:'#fff',fontSize: '18px'}}/></Button>
                  ) : null}
                  {mainTheme.layout == 5 ? (
                    <Button sx={{
                      position:'absolute',
                      zIndex:10,
                      right:'5px',
                      top:'5px',
                      borderRadius:'5px',
                      padding:0,
                      margin:0,
                      maxWidth:'30px',
                      minWidth:'30px',
                      width:'30px',
                      height:'30px',
                      boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset',
                    }} 
                    onClick={handleCloseOrder}
                    color='error' 
                    variant='contained'>
                        <CloseIcon sx={{color:'#fff',fontSize: '18px'}}/>
                    </Button>
                  ) : null}
                  <DialogTitle id="alert-dialog-title" sx={{textAlign:'center',borderBottom:'0.6px solid #eee'}}>
                    <FmdGoodTwoToneIcon sx={{width:'100px',height:'auto',fill:'red',stroke:'white'}}/>
                    <Typography sx={{fontWeight:700}} variant='h5'>Choose Location</Typography>
                    <Typography variant='p'>Powered by Eatstations</Typography>
                  </DialogTitle>
                  <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {alldata && alldata.order_now_urls?.map((order_now_urls)=>(
                      <Box sx={{textAlign:'center',borderBottom:'0.4px solid #eee',py:2}}>
                        <Box sx={{display:'flex',alignItems:'center',m:1}}>
                          <Box sx={{backgroundColor:'#fdc51680',width:'50px',height:'50px',display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%',mx:1}}>
                            <FmdGoodTwoToneIcon sx={{fill:'white',stroke:'black'}}/>
                          </Box>
                          <Typography sx={{fontWeight:700,color:'black'}} variant='h5'>{order_now_urls?.title}</Typography>
                        </Box>
                        <address sx={{m:1}}>{order_now_urls?.address}</address>
                        {mainTheme?.layout == 5 && (
                          <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled button group"
                          >
                              <Button
                                sx={{
                                    my:1.5,
                                    backgroundColor:`${themeData?.button_color}`,
                                    color:`${themeData?.button_font_color}`,
                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                    fontWeight:600,
                                    '&:hover': {
                                    backgroundColor:`${themeData?.button_font_color}`,
                                    color:`${themeData?.button_color}`,
                                    border:`1px solid ${themeData?.button_color}`,
                                    borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                    }
                                }}
                                href={`${order_now_urls?.url}`}
                              >
                                Order Now
                              </Button>
                              <Button href={`${order_now_urls?.url}`} color='warning' sx={{my:1.5}}><AdsClickIcon /></Button>
                          </ButtonGroup>
                        )}
                        {mainTheme.layout == 1 ? (
                          <Button 
                            sx={{
                              my:2,
                              backgroundColor:`${themeData?.button_color}`,
                              color:`${themeData?.button_font_color}`,
                              borderRadius:'50px',
                              fontWeight:600,
                              '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius:'50px',
                              }
                            }} 
                            href={`${order_now_urls?.url}`}
                          >
                            Order Now</Button>
                        ) : null}
                        {mainTheme.layout && mainTheme?.layout == 2 || mainTheme?.layout == 3 || mainTheme?.layout == 4 ? (
                          <Button 
                            sx={{
                              my:2,
                              backgroundColor:`${themeData?.button_color}`,
                              color:`${themeData?.button_font_color}`,
                              borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                              fontWeight:600,
                              '&:hover': {
                                backgroundColor:`${themeData?.button_font_color}`,
                                color:`${themeData?.button_color}`,
                                border:`1px solid ${themeData?.button_color}`,
                                borderRadius:mainTheme?.layout == 1 ? '50px' :mainTheme?.layout == 2 ? '8px':mainTheme?.layout == 3 ? '4px' :'',
                                '& .ads-click-icon': {
                                  color: `${themeData?.button_color}`,
                                }
                              }
                            }} 
                            href={`${order_now_urls?.url}`}
                          >
                            {mainTheme?.layout == 4 ? <AdsClickIcon className='ads-click-icon' sx={{color:'#000',mx:1}} /> : ''}
                            Order Now</Button>
                        ) : null}
                      </Box>
                    ))}
                  </DialogContentText>
                  </DialogContent>
              </Dialog>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }else{
      return null;
    }
}

export default NewsLetter;
